import "./App.css";
import Navigation from "./Component/Navigation/Nevigation";
import { BrowserRouter, Link, Route, Routes, Navigate } from "react-router-dom";
import navigators from "./Common/nav";
import ErrorPage from "./Component/ErrorPage";
import axios from "axios";

function getNavLinks(arr, p = []) {
  arr.forEach((key) => {
    if (key.component) {
      p.push({
        path: key.link,
        element: key.component,
        errorElement: <ErrorPage />,
      });
    }
    if (key.sub_links) {
      getNavLinks(key.sub_links, p);
    }
  });
  return p;
}

function App() {
  if (window.location.host?.includes("localhost")) {
    axios.defaults.baseURL = `http://localhost`;
  }

  const routes = getNavLinks(navigators);
  return (
    <div style={{ height: "100vh", width: "100%", display: "flex" }}>
      <BrowserRouter>
        <Navigation />
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "white",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                height: "4rem",
                display: "flex",
                alignItems: "center",
                margin: "12px",
                fontFamily:
                  '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
              }}
              className="right_two_buttons"
            >
              <a href="/">Welcome Vijay - Admin</a>
              <a href="/logout">Logout</a>
            </div>
          </div>
          <div
            style={{
              margin: "24px",
              flex: 1,
              display: "flex",
              backgroundColor: "white",
              flexDirection: "column",
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              {routes.map((key) => (
                <Route
                  path={key.path + (key.exact ? "" : "/*")}
                  key={key.path}
                  element={key.element}
                />
              ))}
              <Route path={"*"} element={<ErrorPage />} />
            </Routes>
          </div>
          <div style={{ display: "flex", margin: "4px 24px" }}>
            <div style={{ flex: 1 }}>
              <Link path="/" className="btn btn-sm">
                About
              </Link>
              <Link path="/" className="btn btn-sm">
                Support
              </Link>
              <Link path="/" className="btn btn-sm">
                Chat With Us
              </Link>
            </div>
            <span style={{ alignSelf: "flex-end" }} className="btn btn-sm">
              Developed by OV Infinity - Built with Passion v1.1
            </span>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
