import { Link } from "react-router-dom";
import navigators from "../../Common/nav";
import LinkComponent from "./LinkComponent";

function Nevigation({ role }) {
  return (
    <div
      style={{
        width: "240px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
      }}
      className="left navside dark dk"
    >
      <div>
        <Link to="/">
          <img src="/logo.png" alt="." width={"100%"} />
        </Link>
      </div>
      <ul>
        {navigators.map((key) => (
          <LinkComponent key={key.label} {...key} />
        ))}
      </ul>
    </div>
  );
}

export default Nevigation;
