import { NavLink } from "react-router-dom";

function EditDeleteColumn({ data, link }) {
  // const deleteClick = () => {
  // }
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
      }}
    >
      <NavLink to={`${link}/${data.id}`} className="btn btn-primary btn-sm">
        <i className="bi bi-pencil-square"></i>
      </NavLink>
      <button
        path={link}
        className="btn btn-danger btn-sm"
        style={{ marginLeft: "10px" }}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default EditDeleteColumn;
