import { InputFields, SelectList } from "./InputFields";

function FieldCreater(props) {
  const { headerName, outerStyles, labelStyles } = props;
  return (
    <div style={{ flex: 1, ...(outerStyles || {}) }}>
      <label className="form-label" style={{ ...(labelStyles || {}) }}>
        {headerName}
      </label>
      <GetInput {...props} />
    </div>
  );
}

function GetInput(props) {
  const { type } = props;
  switch (type) {
    case "list":
      return <SelectList {...props} />;
    default:
      return <InputFields {...props} />;
  }
}

export default FieldCreater;
