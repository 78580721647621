import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function InputFields({
  field,
  value,
  setData,
  inputStyles,
  formLayoutAttr,
  otherProps,
}) {
  let { type, inputProps } = formLayoutAttr;
  return (
    <input
      type={type || "text"}
      defaultValue={value || ""}
      name={field}
      id={field}
      style={{ ...(inputStyles || {}) }}
      onChange={(e) => {
        setData((prev) => {
          console.log(prev);
          return { ...prev, [field]: e.target.value };
        });
      }}
      className="form-control"
      {...(otherProps || {})}
      {...(inputProps || {})}

    />
  );
}

function SelectList({
  field,
  value,
  headerName,
  setData,
  inputStyles,
  formLayoutAttr,
  otherProps,
}) {
  let { values, table, tableKey } = formLayoutAttr;
  let [dropdownValues, setDropdownValues] = useState(values || []);
  useEffect(() => {
    if (!values) {
      axios
        .get("/get.php?name=" + table)
        .then((res) => {
          setDropdownValues(
            res?.data?.map((key) => ({ id: key.id, [tableKey]: key[tableKey] }))
          );
        })
        .catch((err) => console.log(err));
    }
  }, [values, table, tableKey]);
  return (
    <select
      value={value}
      onChange={(e) => {
        console.log(e);
        setData((prev) => {
          console.log(prev);
          return { ...prev, [field]: e.target.value };
        });
      }}
      name={field}
      className="form-control c-select"
    >
      <option value={""}>Select {headerName}</option>
      {dropdownValues.map((key) => (
        <option value={key.id}>{key[tableKey]}</option>
      ))}
    </select>
  );
}
export { InputFields, SelectList };
