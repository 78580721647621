import { Route, Routes } from "react-router-dom";
import EditDeleteColumn from "./Common/EditDeleteColumn";
import GenericAddCreator from "./Common/GenericAddForm";
import GenericListView from "./Common/GenericListView";

const getColumns = (link) => [
  {
    field: "docId",
    headerName: "ID",
    formLayoutAttr: {
      row: 1,
      outerStyles: {
        width: "10%",
        flex: "none",
      },
      inputProps: { disabled: true },
    },
  },
  {
    field: "name",
    headerName: "Doctor Name",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "qualification",
    headerName: "Qualification",
    formLayoutAttr: {
      row: 1,
    },
  },
  {
    field: "spec",
    headerName: "Specialization",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "freq",
    headerName: "Frequency",
    formLayoutAttr: {
      row: 2,
    },
  },
  {
    field: "city",
    headerName: "City",
    formLayoutAttr: {
      row: 2,
    },
    gridLayoutAttr: {},
  },
  {
    field: "address",
    headerName: "Address",
    formLayoutAttr: {
      row: 2,
    },
  },
  {
    field: "mobile",
    headerName: "Mobile",
    formLayoutAttr: {
      row: 3,
    },
    gridLayoutAttr: {},
  },
  {
    field: "dob",
    headerName: "Dr.DOB",
    formLayoutAttr: {
      row: 3,
      type: "date"
    },
  },
  {
    field: "products",
    headerName: "Products",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    headerName: "Edit | Delete",
    cellRenderer: EditDeleteColumn,
    gridLayoutAttr: {},
    cellRendererParams: {
      link: link + "/upsert",
    },
  },
];

function AddDoctors({ link }) {
  let label = "Doctor";
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GenericListView
            link={link}
            table={"doctors"}
            getColumns={getColumns}
            label={label}
          />
        }
      />
      <Route
        path={"/upsert"}
        element={
          <GenericAddCreator
            getColumns={getColumns}
            label={label}
            table={"doctors"}
            updateData={{}}
            link={link}
          />
        }
      />
    </Routes>
  );
}

export default AddDoctors;
