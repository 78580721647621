import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCreater from "./Form";

function GenericAddCreator({ updateData, link, getColumns, label, table }) {
  const [data, setData] = useState(updateData || {});
  const navigate = useNavigate();

  const onSave = (row) => {
    axios
      .post("/post.php", {
        table: table,
        data: [row],
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
    navigate(link);
  };
  return (
    <FormCreater
      label={label}
      onSave={onSave}
      setData={setData}
      data={data}
      columns={getColumns()}
    />
  );
}

export default GenericAddCreator;
