import { Route, Routes } from "react-router-dom";
import EditDeleteColumn from "./Common/EditDeleteColumn";
import GenericAddCreator from "./Common/GenericAddForm";
import GenericListView from "./Common/GenericListView";

const getColumns = (link) => [
  {
    field: "mr_id",
    headerName: "MR ID",
    formLayoutAttr: {
      row: 1,
      outerStyles: {
        width: "10%",
        flex: "none",
      },
      inputProps: {
        disabled: true,
      },
    },
    gridLayoutAttr: {},
  },
  {
    field: "username",
    headerName: "UserName",
    formLayoutAttr: {
      row: 1,
    },
  },
  {
    field: "name",
    headerName: "Name",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "address",
    headerName: "Address",
    formLayoutAttr: {
      row: 2,
    },
  },
  {
    field: "mobile",
    headerName: "Mobile",
    formLayoutAttr: {
      row: 2,
      type: "tel",
      otherProps: {},
    },
    gridLayoutAttr: {},
  },
  {
    field: "head_quarter",
    headerName: "Head Quarter",
    formLayoutAttr: {
      row: 3,
      type: "list",
      table: "headquarters",
      tableKey: "name",
    },
    gridLayoutAttr: {},
  },
  {
    field: "identity_no",
    headerName: "Identity No.",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "email",
    headerName: "Email ID",
    formLayoutAttr: {
      row: 3,
      type: "email",
    },
  },
  {
    field: "passwd",
    headerName: "Password",
    formLayoutAttr: {
      row: 3,
      type: "password",
    },
  },
  {
    headerName: "Edit | Delete",
    cellRenderer: EditDeleteColumn,
    gridLayoutAttr: {},
    cellRendererParams: {
      link: link + "/upsert",
    },
  },
];

function AddMr({ link }) {
  const label = "MR";
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GenericListView
            link={link}
            table={"mrs"}
            getColumns={getColumns}
            label={label}
          />
        }
      />
      <Route
        path={"/upsert"}
        element={
          <GenericAddCreator
            getColumns={getColumns}
            label={label}
            table={"mrs"}
            updateData={{}}
            link={link}
          />
        }
      />
    </Routes>
  );
}

export default AddMr;
