import { useNavigate } from "react-router-dom";
import FieldCreater from "./FieldCreater";

function FormCreater({ columns, data, label, setData, onSave, isUpdate }) {
  columns = columns.filter((key) => key.formLayoutAttr);
  let rows = [...new Set(columns.map((a) => a?.formLayoutAttr?.row))];

  const navigate = useNavigate();

  return (
    <>
      <div
        className="box-header"
        style={{ alignItems: "center", display: "flex", height: 68 }}
      >
        <h2 style={{ flex: 1 }}>
          <b>
            {isUpdate ? "Update" : "Add New"} {label}
          </b>
        </h2>
      </div>
      <div className="box-divider1" style={{ margin: 0, height: 0 }}></div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: 16,
        }}
      >
        {rows.map((key) => {
          return (
            <div key={key} style={{ display: "flex", gap: 16, margin: "10px" }}>
              {columns.map(
                (fld) =>
                  fld?.formLayoutAttr?.row === key && (
                    <FieldCreater
                      key={fld.name}
                      setData={setData}
                      {...fld}
                      {...(fld.formLayoutAttr || {})}
                    />
                  )
              )}
            </div>
          );
        })}
        <div style={{ display: "flex", gap: 4, margin: "10px" }}>
          <button
            onClick={() => navigate(-1)}
            className="btn btn-danger btn-sm"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              console.log(data);
              onSave(data);
            }}
            className="btn btn-success btn-sm"
          >
            SAVE
          </button>
        </div>
      </div>
    </>
  );
}

export default FormCreater;
