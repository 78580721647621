import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { useEffect } from "react";

function DataGrid({ columns, data }) {
  const onSortChanged = (e) => {
    e.api.refreshCells();
  };
  const gridRef = useRef();
  useEffect(() => {
    if (gridRef && gridRef.current && gridRef.current.columnApi) {
      gridRef.current.columnApi.getColumns();
    }
  }, [gridRef]);
  return (
    <div
      className="ag-theme-material"
      style={{ height: "100%", width: "100%" }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={data || []}
        onSortChanged={onSortChanged}
        defaultColDef={{
          flex: 1,
          filter: "agTextColumnFilter",
          sortable: true,
          resizable: true,
          minWidth: 100,
        }}
        columnDefs={
          [
            {
              headerName: "Sr. No.",
              valueGetter: "node.rowIndex + 1",
            },
            ...columns.map(({field, headerName, gridLayoutAttr}) => ({
              field,
              headerName,
              ...gridLayoutAttr,
            })),
          ] || []
        }
      ></AgGridReact>
    </div>
  );
}

export default DataGrid;
