import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ListViewGeneric from "./ListViewGeneric";

function GenericListView({ label, table, link, getColumns }) {
  const [listData, setListData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    axios
      .get("/get.php?name=" + table)
      .then((res) => {
        console.log(res.data);
        setListData(res.data);
      })
      .catch((err) => console.log(err));
  }, [table, location.key]);

  return (
    <ListViewGeneric
      title={label}
      data={listData}
      link={link}
      columns={getColumns()}
    />
  );
}

export default GenericListView;
