import AddCity from "../Component/AddCity";
import AddDoctors from "../Component/AddDoctors";
import AddMr from "../Component/AddMr";
import Dashboard from "../Component/Dashboard";

let navigators = [
  {
    label: "Dashboard",
    link: "/dashboard",
    classes: "bi bi-grid-3x3-gap-fill",
    component: <Dashboard />,
    role: ["admin"],
  },
  {
    label: "ADMIN",
    role: ["admin"],
    classes: "bi bi-chevron-double-right",
    sub_links: [
      {
        label: "Add Doctors",
        link: "/doctors",
        component: <AddDoctors link="/doctors" />,
        role: ["Admin"],
      },
      {
        label: "Add City",
        link: "/city",
        component: <AddCity  link="/city" />,
        role: ["Admin"],
      },
      {
        label: "Add MR",
        link: "/mr",
        component: <AddMr link="/mr" />,
        role: ["Admin"],
      },
      {
        label: "Add Standard Tour Plan",
        link: "/standardToorPlan",
        component: <div>Dashboar</div>,
        role: ["Admin"],
      },
    ],
  },
  {
    label: "Reports",
    link: "/reports",
    component: <div>Dashboar</div>,
    classes: "bi bi-sliders",
    role: ["admin"],
  },
  {
    label: "Logout",
    link: "/logout",
    component: <div>Dashboar</div>,
    classes: "bi bi-list-ul",
    role: ["admin"],
  },
];

export default navigators;
